<template>
  <div>
    <b-row>
      <b-col class="mt-2 mb-2" cols="12">
        <div class="has-search">
          <d-select-input-search
            :options="clubs"
            :place-holder="$t('admin.duplicate.search-club-model')"
            class-name="bg-white mt-2 mb-3 w-75"
            icon="fa fa-search"
            @on:keydown="searchClubModel($event)"
            @on:selected="onSelect"
          />
        </div>
      </b-col>
      <b-col cols="8" class="simple-label-avenir">
        <h5
          :class="checkedBlockPrice ? 'text-success' : 'text-danger'"
        >
          {{ selectedBlockPrice }}
        </h5>
      </b-col>
      <b-col align="right">
        <div class="has-search">
          <span class="fa fa-search form-control-feedback fa-1-5x"></span>
          <input
            type="text"
            class="form-control"
            v-model="filter"
            :placeholder="$t('components.doinsport.table.search')"
          >
        </div>
      </b-col>
    </b-row>
    <d-table
      identifier="category-shop"
      :tablefields="fields"
      :items="items"
      :per-page="itemsPerPage"
      :totalRows="totalRows"
      :is-busy="isBusy"
      :per-page-enabled="true"
      :tr-cursor-pointer="false"
      @per-page:update="onPerPageUpdate"
      @on:data-table-btn:radio="onDataTableCheck"
      @current-page:update="onPageUpdate"
    >
    </d-table>
  </div>
</template>
<script>
import {getAllCategoriesProducts, getAllProducts} from "@api/services/products/products.api";
import {isNotUndefinedAndNotNull, refine} from "@/utils/classes";
import {getClubs} from "@api/services/club/club.api";
import {getTimetables} from "@api/services/timetable/timetable.api";
import {cloneData} from "@/utils/form";
import {getPricesByActivityType} from "@api/services/timetable/blocks/price/price.api";

export default {
  data: () => ({
    items: [],
    clubs: [],
    filter: '',
    selectedClubId: '',
    isBusy: false,
    checkedBlockPrice: null,
    timer: null,
    itemsPerPage: 10,
    totalRows: 1,
    currentPage: 1,
  }),
  props: {
    reload: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: ''
    },
    isArchived: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    reload: function () {
      this.loadBlockPrices(this.itemsPerPage, this.currentPage, this.filter);
    },
    filter: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadBlockPrices(this.itemsPerPage, this.currentPage, this.filter);
      }, 800);
    },
  },
  computed: {
    selectedBlockPrice() {
      return this.checkedBlockPrice ? this.$t('admin.duplicate.block-price.selected-block-price', {
        cat: this.checkedBlockPrice.name,
        club: 'YouJump',
        name: this.checkedBlockPrice.club.name,
      }) : this.$t('admin.duplicate.block-price.select-block-price');
    },
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    clubList() {
      return this.$store.getters['club/getClubList']
    },
    fields() {
      return [
        {
          key: "name",
          label: this.$t('admin.duplicate.shop-category.title'),
          sortable: true,
        },
        {
          key: "club.name",
          label: this.$t('admin.duplicate.shop-category.club'),
        },
        {
          key: "nextoreProduct.accountSale",
          label: this.$t('admin.duplicate.shop-category.account-sale'),
          class: "text-center"
        },
        {
          key: "actions",
          label: this.$t("general.actions.actions"),
        },
      ];
    },
  },
  methods: {
    onSelect($event) {
      this.$store.commit('blockPrice/setSelectedBlockPriceModel', null);
      this.$store.commit('blockPrice/setCheckedBlockPriceList', []);

      if ($event === null) {
        this.selectedClubId = '';
        this.loadBlockPrices(this.itemsPerPage, this.currentPage, this.filter);
      } else {
        this.selectedClubId = $event.item.id;
        this.loadBlockPrices(this.itemsPerPage, this.currentPage, this.filter);
      }
    },
    onDataTableCheck($event) {
      this.checkedBlockPrice = $event;
      const copyPayload = cloneData($event);
      refine(copyPayload)
      copyPayload.pricePerParticipant !==null ? copyPayload.pricePerParticipant = parseFloat(copyPayload.pricePerParticipant/100).toFixed(2) : copyPayload.pricePerParticipant;
      copyPayload.duration !==null ? copyPayload.duration = parseFloat(copyPayload.duration / 60).toFixed(2) : copyPayload.duration;
      copyPayload.club = $event.club;
      copyPayload.photos = $event.mainPhoto ? [$event.mainPhoto['@id']] : [];
      copyPayload.mainPhoto = $event.mainPhoto;

      this.$store.commit('blockPrice/setSelectedBlockPriceModel', copyPayload);

      setTimeout(() => {
        document.getElementById('blockPriceListDuplicateId').scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 800)
      ;
    },
    onPageUpdate(page) {
      this.loadBlockPrices(this.itemsPerPage, page, this.filter);
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1;
      this.loadBlockPrices(itemsPerPage, this.currentPage, this.filter);
    },
    searchClubModel($event) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadClubs($event)
      }, 400);
    },
    loadBlockPrices(itemsPerPage, currentPage, filter) {
      this.items = [];
      this.isBusy = true;

      getPricesByActivityType(itemsPerPage, currentPage, filter, null,this.selectedClubId)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];

          for (const item of response.data['hydra:member']) {
            item.actions = ['radio'];
            item.checked = true;

            this.items.push(item);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    loadClubs(filter = '') {
      this.clubs = [];
      getClubs(1, 30, filter)
        .then((response) => {
          this.$store.commit('club/setClubs', response.data['hydra:member']);

          for (const club of response.data['hydra:member']) {
            this.clubs.push({
              label: club.name,
              id: club.id,
              item: club,
              iri: club['@id'],
            });
          }
        })
      ;
    },
  },
  created() {
    this.clubs = cloneData(this.clubList);
    this.loadBlockPrices(this.itemsPerPage, this.currentPage, this.filter);
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/_b-card.scss";

/deep/ .card .card-body {
  padding-left: 25px;
  padding-right: 25px;
}

/deep/ .page-item.active .page-link {
  z-index: 0;
  color: #fff;
  background-color: #092772;
  border-color: #092772;
}
</style>
